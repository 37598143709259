import loadable from "@loadable/component"
import React, { useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useGlobalStore } from "../../store/globalStore"
import { calcPrice } from "../CurrencyConverter/CurrConvertPrice"
import { filterCustomStyles } from "../DropdownSelectSettings/DropdownSelectSettings"
import "./assets/styles/_index.scss"
import { calculateMonthlyPayment } from "./mortgage"

const {
  filterNumber,
  numberFormat,
  pmt,
} = require("@starberry/gatsby-theme-utils/Common/Utils")

// User project specific const
const durationOptions = [
  { value: 10, label: "10" },
  { value: 15, label: "15" },
  { value: 20, label: "20" },
  { value: 25, label: "25" },
]

const Site_Vars = { default_currency: "£" }

const Select = loadable(() => import("react-select"))

const MortageCalc = (props) => {
  const { currencyType } = useGlobalStore()

  const {
    propertyPrice,
    depositAmount,
    loadDuration,
    interestRate,
    currency,
    defaultResult,
    pricePrefix,
  } = props

  const prefix = ""
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [monthlyPayment, setMonthlyPayment] = useState(0)

  const [purchasePrice, setPurchasePrice] = useState(
    prefix + numberFormat(propertyPrice)
  )
  const depValue = calcPrice(purchasePrice, currencyType.value, "AED")
  const dep_price = (depValue / 100) * 20

  const [deposit, setDeposit] = useState(prefix + numberFormat(dep_price))
  const [duration, setDuration] = useState(20)
  // const [duration, setDuration] = useState(loadDuration)
  const [interest, setInterest] = useState(4.8)
  const [loan, setLoan] = useState("")
  const [result, setResult] = useState("")
  const [depositError, setDepError] = useState(false)

  const handlePrice = (event) => {
    const val = filterNumber(event.target.value)
    if (
      event.target.value !== "£" &&
      event.target.value !== "£0" &&
      event.target.value !== "0" &&
      event.target.value !== ""
    ) {
      setPurchasePrice(prefix + numberFormat(val))
      const newDeposit = (val / 100) * 20
      setDeposit(prefix + numberFormat(newDeposit))
      if (parseInt(filterNumber(newDeposit)) && parseInt(val)) {
        const loan = parseInt(val) - parseInt(filterNumber(newDeposit))
        setLoan(prefix + numberFormat(loan))
      } else {
        setLoan(prefix + 0)
      }
      setMonthlyPayment(
        calculateMonthlyPayment(
          parseInt(filterNumber(event.target.value)),
          parseFloat(interest),
          parseInt(filterNumber(newDeposit)),
          duration
        )
      )
      setShowerror(false)
    } else {
      setPurchasePrice("")
      setLoan()
      setMonthlyPayment(0)
    }
  }

  const handleSubmit = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "formSubmit",
      formType: "Mortage calculator",
      formId: "Mortage calculator",
      formName: "Mortage calculator",
      formLabel: "Mortage calculator",
    })
    setMonthlyPayment(
      calculateMonthlyPayment(
        parseInt(filterNumber(purchasePrice)),
        parseFloat(interest),
        parseInt(filterNumber(deposit)),
        duration
      )
    )
  }

  const handleDeposit = (event) => {
    const d = filterNumber(event.target.value)
    if (
      event.target.value !== "£" &&
      event.target.value !== "£0" &&
      event.target.value !== "0" &&
      event.target.value !== ""
    ) {
      setDeposit(prefix + numberFormat(d))

      if (parseInt(filterNumber(purchasePrice)) && parseInt(d)) {
        const loan2 = parseInt(filterNumber(purchasePrice)) - parseInt(d)
        setLoan(prefix + numberFormat(loan2))
        handleSubmit()
      } else {
        setLoan(prefix + 0)
      }
      const par = purchasePrice.replace("£", "").replace(/,/g, "")
      const dep = event.target.value.replace("£", "").replace(/,/g, "")
      const par1 = parseInt(par)
      const dep1 = parseInt(dep)
      if (par1 < dep1 || par1 === dep1) {
        setDepError(true)
        setMonthlyPayment(0)
      } else {
        setDepError(false)
        setMonthlyPayment(
          calculateMonthlyPayment(
            parseInt(filterNumber(purchasePrice)),
            parseFloat(interest),
            parseInt(filterNumber(event.target.value)),
            duration
          )
        )
      }
      setShowerror(false)
    } else {
      setLoan("")
      setDeposit("")
      setMonthlyPayment(0)
    }
  }

  const handleNewDeposit =(event)=>{
    const d = filterNumber(event.target.value)
    if (
      event.target.value !== "£" &&
      event.target.value !== "£0" &&
      event.target.value !== "0" &&
      event.target.value !== ""
    ) {
      setDeposit(prefix + numberFormat(d))

      if (parseInt(filterNumber(purchasePrice)) && parseInt(d)) {
        const loan2 = parseInt(filterNumber(purchasePrice)) - parseInt(d)
        setLoan(prefix + numberFormat(loan2))
        handleSubmit()
      } else {
        setLoan(prefix + 0)
      }
      const par = purchasePrice.replace("£", "").replace(/,/g, "")
      const dep = event.target.value.replace("£", "").replace("AED", "").replace("USD","").replace("EUR", "").replace("GBP", "").replace(/,/g, "")
      const par1 = parseInt(par)
      const dep1 = parseInt(dep)
      if (par1 < dep1 || par1 === dep1) {
        setDepError(true)
        // setMonthlyPayment(0)
      } else {
        setDepError(false)
        setMonthlyPayment(
          calculateMonthlyPayment(
            parseInt(filterNumber(purchasePrice)),
            parseFloat(interest),
            parseInt(filterNumber(event.target.value)),
            duration
          )
        )
      }
      setShowerror(false)
    } else {
      setLoan("")
      setDeposit("")
      setMonthlyPayment(0)
    }
  }

  const handleDuration = (event) => {
    setDuration(filterNumber(event.value))
    // handleSubmit()
    setMonthlyPayment(
      calculateMonthlyPayment(
        parseInt(filterNumber(purchasePrice)),
        parseFloat(interest),
        parseInt(filterNumber(deposit)),
        filterNumber(event.value)
      )
    )
  }

  const handleInterest = (event) => {
    setInterest(event.target.value.replace(/[^\d.]/g, ""))
    // handleSubmit()
    setMonthlyPayment(
      calculateMonthlyPayment(
        parseInt(filterNumber(purchasePrice)),
        parseFloat(event.target.value),
        parseInt(filterNumber(deposit)),
        duration
      )
    )
  }

  const handleLoan = (event) => {
    setLoan(prefix + numberFormat(filterNumber(event.target.value)))
  }

  const getResult = (interest, duration, loan) => {
    const result = -pmt(
      interest / 100 / 12,
      filterNumber(duration) * 12,
      filterNumber(loan),
      0,
      1
    )
    setResult(numberFormat(Math.round(result)))
  }

  useEffect(() => {
    if (defaultResult) {
      if (
        parseInt(filterNumber(purchasePrice)) &&
        parseInt(filterNumber(deposit))
      ) {
        const loan =
          parseInt(filterNumber(purchasePrice)) -
          parseInt(filterNumber(deposit))
        setLoan(prefix + numberFormat(loan))
      }
      getResult(interest, duration, loan)
      setMonthlyPayment(
        calculateMonthlyPayment(
          parseInt(filterNumber(purchasePrice)),
          parseFloat(interest),
          parseInt(filterNumber(deposit)),
          duration
        )
      )
    }
  }, [defaultResult, purchasePrice, deposit, loan, interest, duration, prefix])

  useEffect(() => {
    if (
      parseInt(filterNumber(purchasePrice)) &&
      parseInt(filterNumber(deposit))
    ) {
      const loan =
        parseInt(filterNumber(purchasePrice)) - parseInt(filterNumber(deposit))
      setLoan(prefix + numberFormat(loan))
    }
    getResult(interest, duration, loan)
    setMonthlyPayment(
      calculateMonthlyPayment(
        parseInt(filterNumber(purchasePrice)),
        parseFloat(interest),
        parseInt(filterNumber(deposit)),
        duration
      )
    )
  }, [])

  useEffect(()=>{
    setDeposit(prefix + numberFormat(dep_price))
  },[currencyType.value])

  // DO NOT DO ANY CHNAGES - END
  const monthly_payment = Math.round(monthlyPayment)
  const selectvalues = []
  {
    durationOptions.map((val) => selectvalues.push({ value: val, label: val }))
  }

  const calcOptions = [
    { value: "first", label: "First Time Buyer" },
    { value: "home", label: "I’m buying my home" },
    { value: "investor", label: "I’m buying an additional home" },
  ]

  return (
    <div
      className={
        props?.isPage
          ? "page property-detail-calc-wrapper"
          : "property-detail-calc-wrapper"
      }
    >
      {props?.isPage ? (
        <h2>Mortgage Calculator</h2>
      ) : (
        <h6>Mortgage Calculator</h6>
      )}
      {props?.isPage ? null : (
        <p className="calc-text">
          Calculate and view the monthly mortgage on this Apartment
        </p>
      )}
      <Form
        name="MortgageCalc"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        autoComplete="off"
        className="calculator"
      >
        {showerror && (
          <div className="alert alert-danger">
            <p>Highlighted fields are required</p>
          </div>
        )}
        {depositError && (
          <div className="alert alert-danger">
            <p>Please enter deposit amount less than the property amount.</p>
          </div>
        )}
        <Row>
          <Col md={3}>
            <Form.Group className="theme-form-group">
              <Form.Label for="property_price">Price *</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Price"
                value={`${currencyType.label} ${calcPrice(purchasePrice, currencyType.value, "AED").toLocaleString()}`}
                onChange={handlePrice}
                maxLength={13}
                disabled={!props?.isPage}
                id="property_price"
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="theme-form-group">
              <Form.Label for="property_deposit">Deposit (20%) *</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder=""
                // value={`${currencyType.label} ${parseInt(
                //   calcPrice(deposit, currencyType.value, "AED")
                // ).toLocaleString()}`}
                // onChange={handleDeposit}
                value={`${currencyType.value} ${deposit}`}
                onChange={handleNewDeposit}
                maxLength={13}
                id="property_deposit"
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="theme-form-group">
              <Form.Label for="property_mortgage_term">
                Repayment Term *
              </Form.Label>
              <div className="dropdown-select d-flex align-items-center">
                <Select
                  name="duration"
                  defaultValue={durationOptions[2]}
                  options={durationOptions}
                  isSearchable={false}
                  className="select-control"
                  classNamePrefix="react-select"
                  styles={filterCustomStyles}
                  id="property_mortgage_term"
                  components={{
                    DropdownIndicator: () => (
                      <i className="icon icon-select-dropdown-dark" />
                    ),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={handleDuration}
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="theme-form-group">
              <Form.Label for="property_interest_rate">
                Interest Rate (%) *
              </Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Interest Rate"
                value={interest}
                maxLength={4}
                onChange={handleInterest}
                id="property_interest_rate"
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <div className="monthly-pay-wrapper">
        <div className="monthly-pay-title">Monthly Payments</div>
        {monthly_payment ? 
        <div className="monthly-pay-amount">
          {/* {Site_Vars.default_currency} */}
          {`${currencyType.label} ${parseInt(
            calcPrice(monthly_payment, currencyType.value, "AED")
          ).toLocaleString()}`}{" "}
          / month
        </div> : <div className="alert alert-danger alert-error">
          <p>* All fields are required</p>
        </div>}
      </div>

      {/* <div className="divider-line" /> */}

      {/* <div className="calc-help-text"><span>Need help with a mortgage?</span> <Link to={`/${PageLinks.mortgage_form}/`}>Get in touch</Link> for all your mortgage needs.</div> */}
    </div>
  )
}

export default MortageCalc
